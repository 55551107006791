@import "../../assets/styles/utils";

.comp-footer {
  width: 100%;
  &-body {
    &-wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      color: $color-fs-gray;
      background-color: #2E2E2E;
      @include res(padding-left, 125px, 100 / 125);
      @include res(padding-right, 125px, 100 / 125);
      @include res(padding-top, 60px, 40 / 60);
      @include res(padding-bottom, 60px, 40 / 60);
      .otherfooter{
        position: absolute;
        bottom: 21%;
        left: 26%;
        .comp-footer-title{
          margin-top: 10px;
          @include res(margin-bottom, 44px, 30 / 44);
        }
        .otherfooter_all{
          display: flex;
          align-items: center;
          justify-content: center;
          .gz{
            position: relative;
            margin-right: 26px;
            cursor: pointer;
            transition: all .3s;
            .gz_icon{
              font-size: 36px;
              transition: all .3s;
            }
            .otherfooter_item{
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              margin:0 10px;
              position: absolute;
              bottom: -84%;
              left: 24%;
              transform: translate(-50%,-50%);
              background-color: #fff;
              padding: 10px;
              z-index: 9;
              opacity: 0;
              visibility: hidden;
              transition: all .3s;
              .sjicon{
                position: absolute;
                bottom: -22px;
                left: 50%;
                transform: translate(-50%,-50%);
                color: #fff;
              }
              img{
                width: 140px;
              }
              p{
                margin-top: 6px;
                text-align: center;
              }
            }
            &:hover{
              .gz_icon{
                color: #fff;
              }
              .otherfooter_item{
                opacity: 1;
                visibility: inherit;
              }
            }
          }
        }
      }
    }
  }
  &-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #fff;
    @include res(font-size, 20px, 16 / 20);
    @include res(margin-bottom, 40px, 30 / 40);
    i {
      color: $color-fs-gray;
      margin-left: 12px;
    }
  }
  &-item {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid rgba(#fff, 0.1);
    @include res(font-size, 16px, 13 / 16);
    // hover
    & {
      transition: all .15s;
    }
    &:hover {
      color: #fff;
    }
  }
  &-contact {
    display: flex;
    align-items: center;
    &-qr {
      @include res(margin-right, 20px, 10 / 20);
      img{
        max-width: 177px;
      }
    }
    &-body {
      display: flex;
      flex-direction: column;
      line-height: 1.8;
      p {
        color: $color-fs-gray;
      }
      b {
        color: #fff;
        @include res(font-size, 40px, 22 / 40);
      }
      i {
        display: block;
        height: 1px;
        background-color: $color-green;
        margin: 8px 0;
        @include res(width, 38px, 26 / 38);
      }
    }
  }
  &-footer {
    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
    }
  }
  &-top {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 55px;
    font-size: 14px;
    transform: translateX(25px);
    &-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      color: #fff;
      background-color: $color-green;
      margin-left: 14px;
    }
    // hover
    & {
      transition: all .2s;
      cursor: pointer;
    }
    &:hover {
      transform: translateY(-5px) translateX(25px);
    }
  }
}