@import "../../assets/styles/utils";

$space-left: 310px;

.comp-banner {
  position: relative;
  @include res(margin-bottom, 95px, 70 / 95);
  &-body {
  }
  &-pic {
    display: block;
    width: 100%;
  }
  &-title {
    position: absolute;
    top: 50%;
    left: 0;
    color: #fff;
    padding-left: $space-left;
    @include res(font-size, 60px, 50 / 60);
  }
  &-nav {
    @include res(margin-top, -65px, 0.7);
    &-wrapper {
      position: relative;
      display: flex;
      margin-left: $space-left - $layout-container-gutter;
      background-color: $color-green-light;
      padding: 40px 90px;
      color: $color-fs-gray;
      @include res(font-size, 20px, 18 / 20);
    }
    &-item {
      position: relative;
      padding: 10px 0;
      &:not(:last-child) {
        @include res(margin-right, 95px, 70 / 95);
      }
      // hover
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 0;
        transition: all .25s;
        right: 0;
        background-color: $color-green;
      }
      &:hover, &.active {
        &:before {
          left: 0;
          right: auto;
          width: 100%;
        }
      }
    }
  }
}