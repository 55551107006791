@import "../../assets/styles/utils";

.comp-header {
  background-color: #fff;
  &-wrapper {
    display: flex;
    height: $header-height;
    align-items: center;
  }
  &-logo {
    &-inner {
      max-width: 18vw;
      min-width: 240px;
    }
  }
  &-nav {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    &-item {
      @include res(margin-right, 45px, 20 / 45);
      @include res(font-size, 20px, 18 / 20);
      // hover
      & {
        transition: all .15s;
      }
      &:hover, &.active {
        color: $color-green;
      }
    }
  }
  &-pos, &-hamburger {
    height: 45px;
    border: 1px solid #BFBFBF;
  }
  &-pos {
    display: flex;
    align-items: center;
    border-radius: 50000px;
    cursor: pointer;
    @include res(padding-left, 26px, 14 / 26);
    @include res(padding-right, 26px, 14 / 26);
    p {
      margin: 0 5px;
      font-size: 18px;
    }
    i:first-child {
    }
    i:last-child {
    }
    // hover
    & {
      transition: all .15s;
    }
    &:hover {
      background-color: rgba(#000, 0.1);
    }
  }
  &-hamburger {
    //display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    border-radius: 50%;
    margin-left: 12px;
    display: none !important;
  }
  &-city {
    display: none;
    @include res(padding, 50px, 30 / 50);
    &-title {
      @include res(font-size, 30px, 18 / 30);
      @include res(margin-bottom, 40px, 20 / 40);
    }
    &-list {
    }
    &-item {
      position: relative;
      height: 174px;
      width: 174px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F1F1F1;
      @include res(font-size, 22px, 16 / 22);
      img {
        position: absolute;
        right: 0;
        bottom: 0;
      }
      // hover
      & {
        transition: all .15s;
      }
      &:hover, &.active {
        color: #fff;
        background-color: $color-green;
      }
    }
    [data-fancybox-close] {
      position: absolute;
      right: 15px !important;
      top: 15px !important;
      height: 46px;
      width: 46px;
      color: #fff !important;
      background-color: $color-green;
      border-radius: 50%;
      transition: all .15s;
    }
  }
}