@import "../../assets/styles/utils";

.comp-job-layout {
  &-aside {
    position: sticky;
    left: 0;
    top: $header-height;
    z-index: 10;
  }
  &-title {
    border-bottom: 1px solid $color-green;
    @include res(font-size, 26px, 24 / 26);
    @include res(padding-left, 35px, 30 / 35);
    @include res(padding-right, 35px, 30 / 35);
    @include res(padding-top, 30px, 25 / 30);
    @include res(padding-bottom, 30px, 25 / 30);
  }
  &-tab {
    a {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        &:after {
          content: "";
          height: 5px;
          width: 5px;
          border-radius: 50%;
          background-color: #C2C2C2;
          @include res(margin-left, 26px, 20 / 26);
          @include res(margin-right, 26px, 20 / 26);
        }
      }
      p {
        padding: 12px 0;
      }
      // hover
      p {
        transition: all .15s;
        border-bottom: 1px solid transparent;
      }
      &.active, &:hover {
        p {
          color: $color-green;
          border-bottom: 1px solid $color-green;
        }
      }
    }
  }
  &-download {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: $color-green;
    padding-left: 15px;
    padding-right: 15px;
    @include res(padding-top, 44px, 38 / 44);
    @include res(padding-bottom, 44px, 38 / 44);
    p {
      @include res(margin-bottom, 25px, 20 / 25);
      @include res(font-size, 16px, 14 / 16);
    }
    a {
      display: flex;
      align-items: center;
      text-decoration: underline;
      @include res(font-size, 24px, 22 / 24);
    }
    img {
      margin-right: 8px;
    }
  }
  &-hint {
    line-height: 1.8;
    background-color: $color-green-light;
    @include res(padding, 70px, 60 / 70);
    h3 {
      @include res(font-size, 26px, 24 / 26);
      @include res(margin-bottom, 25px, 20 / 20);
    }
    p {
      color: $color-fs-gray;
    }
    a {
      color: $color-green;
    }
  }
}