@import "../../assets/styles/utils";

.comp-tab {
  display: flex;
  font-size: 22px;
  &-item {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      &:after {
        content: "";
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: #C2C2C2;
        @include res(margin-left, 26px, 20 / 26);
        @include res(margin-right, 26px, 20 / 26);
      }
    }
    p {
      padding: 12px 0;
    }
    // hover
    p {
      transition: all .15s;
      border-bottom: 1px solid transparent;
    }
    &.active, &:hover {
      p {
        color: $color-green;
        border-bottom: 1px solid $color-green;
      }
    }
  }
}