@import "../../assets/styles/utils";

.comp-project {
  display: block;
  background-color: $color-green-light;
  &-media {
    position: relative;
    padding-bottom: 60%;
    overflow: hidden;
    background-color: #000;
    &-pic {
      opacity: 0.7;
      @include cover();
      @include bg-cover;
    }
    &-footer {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      position: absolute;
      bottom: 18px;
      left: 0;
      color: #fff;
      padding: 0 40px;
      p:first-child {
        @include res(font-size, 30px, 24 / 30);
        span {
          font-size: 16px;
          margin-left: 5px;
        }
      }
      p:last-child {

      }
    }
  }
  &-body {
    line-height: 2;
    padding: 26px 40px;
  }
  &-title {
    font-weight: bold;
    @include res(font-size, 32px, 22 / 32);

  }
  &-subtitle {
    font-weight: bold;
    color: $color-green;

    @extend %ellipsis;
  }
  &-line {
    display: block;
    height: 1px;
    background-color: rgba(#000, 0.2);
    margin: 8px 0;
  }
  &-desc {
    color: $color-fs-gray;
    p {
      @extend %ellipsis;
    }
    i {
      margin-right: 6px;
      color: rgba($color-green, 0.6);
    }
  }
  // hover
  // comp-project 和 comp-article 组件通用 hover，直接赋值即可
  &, &-media-pic, &-media-inner {
    transition: all .35s;
  }
  & {
    border-bottom: 2px solid transparent;
  }
  &:hover {
    background-color: #fff;
    //transform: translateY(-6px);
    box-shadow: 5px 5px 0 $color-green-light;
    border-bottom-color: $color-green;
  }
  &:hover &-media-pic,
  &:hover &-media-inner {
    transform: scale(1.1);
  }
}