@import "../../assets/styles/utils";

.page {
  margin-top: 30px;
  font-family: "Microsoft YaHei", sans-serif;
  &, .page {
    display: flex;
    align-items: center;
    width: 100%;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $color-green;
    transition: all .15s;
    color: $color-green;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    &:not(:last-child) {
      margin-right: 20px;
    }
    // hover
    & {
      transition: all .25s;
    }
    &:hover,&.hover {
      color: #fff;
      background-color: $color-green;
    }
  }
}