@import "../../assets/styles/utils";

.comp-control-pagination {
  display: flex;
  z-index: 1;
  i {
    position: relative;
    border-radius: 50%;
    border: 4px solid transparent;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #fff;
    }
  }
  i:not(:last-child) {
    margin-right: 12px;
  }

  // theme
  &.black i:before {
    background-color: #CECECE;
  }

  // white active
  i.active {
    border-color: rgba(#fff, 0.3);
  }

  // black active
  &.black i.active {
    background-color: rgba($color-green, 0.4);
    &:before {
      background-color: $color-green;
    }
  }

}