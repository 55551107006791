@font-face {
  font-family: "iconfont"; /* Project id 1514956 */
  src: url('iconfont.woff2?t=1703484029264') format('woff2'),
       url('iconfont.woff?t=1703484029264') format('woff'),
       url('iconfont.ttf?t=1703484029264') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sanjiao01:before {
  content: "\e810";
}

.icon-xiaochengxu:before {
  content: "\e63e";
}

.icon-weixin:before {
  content: "\e601";
}

.icon-douyin:before {
  content: "\e7af";
}

.icon-shipinhao:before {
  content: "\eb73";
}

.icon-jiahao:before {
  content: "\e61c";
}

.icon-close:before {
  content: "\e68c";
}

.icon-user:before {
  content: "\e61e";
}

.icon-arrow-left:before {
  content: "\e613";
}

.icon-arrow-top:before {
  content: "\e68a";
}

.icon-arrow-right:before {
  content: "\e68b";
}

.icon-phone:before {
  content: "\e600";
}

.icon-pos-map:before {
  content: "\e669";
}

.icon-search:before {
  content: "\e6a5";
}

.icon-position:before {
  content: "\e712";
}

.icon-arrow-bottom:before {
  content: "\e689";
}

