@import "../../assets/styles/utils";
@import '~jason-webmap/src/style.scss';

.jason-map {
  &-custom-select {
    display: flex;
    color: $color-fs-gray;
    margin-top: 30px;
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      width: 160px;
      height: 38px;
      border: 2px solid $color-fs-gray;
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
    &-label {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 10px;
    }
    &-list {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all .15s;
      background-color: #fff;
      height: 400px;
      overflow: auto;
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 0;
      }
    }
    &-link {
      display: block;
      padding: 10px;
      transition: all .15s;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(#000, 0.15);
      }
      &:hover {
        background-color: $color-green;
        color: #fff;
      }
    }
    &-item:hover &-list {
      opacity: 1;
      visibility: visible;
    }
  }
}