@import "../../assets/styles/utils";

.comp-control-btn {
  display: flex;
  align-items: center;
  &-item {
    position: relative;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    cursor: pointer;
    transition: all .15s;
    outline: none;
    &:not(:last-child) {
      margin-right: 15px;
    }
    // theme
    .white & {
      color: #fff;
    }
    .green & {
      color: $color-green;
    }
    .gray & {
      color: #ACACAC;
      border: 1px solid #ACACAC;
      border-radius: 50%;
      &:after {
        display: none !important;
      }
    }
    // disabled
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    // white 和 green hover
    &:after {
      content: "";
      border-radius: 50%;
      opacity: 0;
      transform: scale(1.3);
      filter: blur(3px);
      transition: all .4s;
      @include cover;
      // theme
      .white & {
        border: 2px solid #fff;
      }
      .green & {
        border: 2px solid $color-green;
      }
    }
    &:hover:after {
      opacity: 1;
      transform: scale(1);
      filter: blur(0);
    }

    // gray hover
    .gray &:hover {
      color: #fff;
      border-color: $color-green;
      background-color: $color-green;
    }
  }
}