@import "../../assets/styles/utils";

.comp-article {
  display: flex;
  flex-wrap: wrap;
  background-color: $color-green-light;
  &-media {
    position: relative;
    flex-shrink: 0;
    overflow: hidden;
    .x & {
      width: (100% / 12 * 4);
      max-height: 350px;
    }
    .y & {
      width: 100%;
      height: 270px;
    }
    &-inner {
      @include cover;
      @include bg-cover;
    }
  }
  &-body {
    flex-grow: 1;
    .x & {
      width: (100% / 12 * 8);
      @include res(padding-left, 50px, 40 / 50);
      @include res(padding-top, 75px, 60 / 75);
      @include res(padding-right, 50px, 40 / 50);
      @include res(padding-bottom, 105px, 80 / 100);
    }
    .y & {
      width: 100%;
      @include res(padding-left, 50px, 40 / 50);
      @include res(padding-top, 40px, 35 / 40);
      @include res(padding-right, 50px, 40 / 50);
      @include res(padding-bottom, 120px, 90 / 120);
    }
    &-time {
      display: block;
      color: $color-fs-gray;
      @include res(margin-bottom, 22px, 18 / 22);
    }
    &-title {
      line-height: 1.4;
      @include res(font-size, 24px, 22 / 24);
    }
    &-desc {
      color: $color-fs-gray;
      line-height: 1.7;
      @include res(margin-top, 24px, 18 / 24);
      .y & {
        display: none;
      }
    }
  }
  // hover
  // comp-project 和 comp-article 组件通用 hover，直接赋值即可
  &, &-media-pic, &-media-inner {
    transition: all .35s;
  }
  & {
    border-bottom: 2px solid transparent;
  }
  &:hover {
    background-color: #fff;
    //transform: translateY(-6px);
    box-shadow: 5px 5px 0 $color-green-light;
    border-bottom-color: $color-green;
  }
  &:hover &-media-pic,
  &:hover &-media-inner {
    transform: scale(1.1);
  }
}
/**/
.finish{
  line-height: 1.8;
  background-color: rgba(0,0,0,.6);
  padding:10px 30px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9;
  color: #fff;
  text-align: center;
}