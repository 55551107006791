@import "../../assets/styles/utils";

.comp-root {
  min-width: 1280px;
  &-header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 50;
  }
  &-body {
    position: relative;
    background-color: #fff;
    z-index: 1;
    overflow: hidden;
    @include res(padding-bottom, 50px, 40 / 50);
  }
  &-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}