@import "../../assets/styles/utils";

.comp-search {
  display: inline-flex;
  height: 50px;
  background-color: #fff;
  border-bottom: 2px solid #CAD7DA;
  &-input {
    height: 100%;
    padding: 10px 20px;
    border: none;
    outline: none;
    @include res(width, 440px, 380 / 440);
  }
  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 50px;
    border: none;
    background-color: $color-green;
    transition: all .15s;
    cursor: pointer;
    font-size: 24px;
    &:hover {
      background-color: darken($color-green, 10%);
    }
  }
}