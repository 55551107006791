@import "./utils";
@import "~jason-css/src/responsive";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'din-medium';
  src: url("../fonts/din-medium.ttf");
  /* IE9 兼容模式 */
  src: url("../fonts/din-medium.eot");
  /* IE6~IE8 */
  src: url("../fonts/din-medium.eot") format("embedded-opentype"),
    /* 现代游览器 */
  url("../fonts/din-medium.woff") format("woff"),
    /* Safari，Android，IOS */
  url("../fonts/din-medium.ttf") format("truetype"),
    /* Legacy iOS */
  url("../fonts/din-medium.svg") format("svg");
}

body {
  color: $color-fs-black;
  font-family: "din-medium", "Microsoft YaHei", sans-serif;
}



@media (max-width: 1366px) {
  .container-fluid {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}
.position-relative{
  position: relative;
}